import React from "react"
import Lottie from "react-lottie"
import animationData from "../static/lottie/search"

const WelcomeView = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div>
      <Lottie options={defaultOptions} width={"40vmin"} height={"40vmin"} isPaused={false} isStopped={false} />
      <h3 style={{ color: "#c3c3c3" }}>No integration method called</h3>
    </div>
  )
}

export default WelcomeView
