import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import "./App.css"
import Layout from "./components/Layout"
import Slack from "./integrations/slack"
import Welcome from "./components/WelcomeView"
import NotFoundView from "./components/404Page"

const App = () => (
  <div className="App">
    <Layout>
      <Router>
        <div>
          <Switch>
            <Route path="/" exact component={Welcome} />
            <Route path="/slack/" component={Slack} />
            <Route component={NotFoundView} />
          </Switch>
        </div>
      </Router>
    </Layout>
    <ToastContainer />
  </div>
)

export default App
