import React from "react"
import Lottie from "react-lottie"
import animationData from "../static/lottie/preloader"

const LoadingView = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} width={"40vmin"} height={"25vmin"} isPaused={false} isStopped={false} />
      Thanks for installing the Kenai Notifications app. <br /> We are processing your request.
    </div>
  )
}

export default LoadingView
