import React from "react"
import styled from "styled-components"

import KenaiLogo from "../static/images/kenai-logo.svg"
import KenaiBot from "../static/images/icon-kenai-bot.svg"

const LayoutWrapper = styled.section`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: #282c34;
  transition: all 0.3s ease-in-out;
  .flex-footer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .branding-image {
    height: 15vmin;
    max-height: 50px;
    margin: 15px;
    transition: filter 0.3s ease-in-out;
    filter: grayscale(100%) contrast(0%);
    opacity: 0.3;
    &:hover {
      filter: none;
      opacity: 1;
    }
  }
  .failed-view & {
    background: #12253c;
    color: white;
  }
`

export default class Layout extends React.Component {
  render() {
    return (
      <LayoutWrapper>
        {this.props.children}
        <div className="flex-footer-container">
          <img src={KenaiBot} alt="Kenai Bot" className="branding-image" />
          <img src={KenaiLogo} alt="Kenai" className="branding-image" />
        </div>
      </LayoutWrapper>
    )
  }
}
