import React from "react"
import Lottie from "react-lottie"
import animationData from "../static/lottie/check"

const SuccesView = (props) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div>
      <Lottie options={defaultOptions} width={"40vmin"} height={"30vmin"} isPaused={false} isStopped={false} />
      We have successfully processed your request <br />
      <p>
        Your reference is <strong>{props.teamId}</strong>{" "}
      </p>
      Please send this to your Kenai support representative to complete activation of Kenai Notifications
    </div>
  )
}

export default SuccesView
