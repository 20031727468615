import React, { Component } from 'react'

import LoadingView from '../components/LoadingView'
import SuccesView from '../components/SuccessView'
import FailedView from '../components/FailedView'
import copyTextToClipboard from '../utils/CopyToClipboard'

class Slack extends Component {
  state = {
    loading: true,
    team_id: '',
    success: false,
    error: '',
  }

  componentDidMount() {
    this.checkToken()
  }

  checkToken = async () => {
    try {
      const queryString = window.location.search
      if (queryString && queryString.indexOf('code=') > -1) {
        //submit code
        const queryPart = queryString.split('code=')[1]
        const codePart = queryPart.split('&')[0]
        const response = await fetch(`/api${window.location.pathname}`, {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: codePart,
          }),
        })
        if (response) {
          const json = await response.json()
          if (json.ok === true && json.team_id && json.team_id.length && json.team_id.length > 0) {
            //process access token
            copyTextToClipboard(json.team_id)
            this.setState({ loading: false, team_id: json.team_id, success: true })
          } else {
            this.setState({ loading: false, success: false })
          }
        }
      } else {
        this.setState({ loading: false, success: false })
      }
    } catch (error) {
      this.setState({ loading: false, success: false })
    }
  }

  render() {
    const { loading, success, error } = this.state
    if (loading) {
      return <LoadingView />
    } else if (success) {
      return <SuccesView teamId={this.state.team_id} />
    } else {
      return <FailedView message={error} />
    }
  }
}

export default Slack
