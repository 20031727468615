import { toast } from "react-toastify"

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea")
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand("copy")
    if (successful) {
      toast("Reference copied to clipboard", { autoClose: 4000, closeButton: false })
    } else {
      // console.log("Fallback: Oops, unable to copy")
    }
  } catch (err) {
    // console.error("Fallback: Oops, unable to copy", err)
  }

  document.body.removeChild(textArea)
}

export default function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function() {
      toast("Reference copied to clipboard", { autoClose: 4000, closeButton: false })
    },
    function(err) {
      // console.log("Async: Could not copy text: ", err)
    }
  )
}
