import React from "react"
import FailedImage from "../static/images/status-pages/500-error.svg"

const NotFoundView = () => {
  document.body.classList && document.body.classList.add("failed-view")
  return (
    <div>
      <img src={FailedImage} alt="FAILED" style={{ width: "100vmin", height: "60vmin" }} />
      <br />
      <h1>Ooops...</h1> We cant seem to find the page you are looking for
      <br />
    </div>
  )
}

export default NotFoundView
