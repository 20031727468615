import React from "react"
import FailedImage from "../static/images/status-pages/500-error.svg"

const FailedView = props => {
  document.body.classList && document.body.classList.add("failed-view")
  return (
    <div>
      <img src={FailedImage} alt="FAILED" style={{ width: "100vmin", height: "60vmin" }} />
      <br />
      We had a problem processing your request - please try again or contact technical support <a style={{color: "white"}} href="mailto:support@kenai.co.za">(support@kenai.co.za)</a><br />
      <br />
      {props.message && props.message.length && props.message.length > 0 && <code>Reason: {props.message}</code>}
    </div>
  )
}

export default FailedView
